import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useAuth } from "@cayuse/shared-react.utils.auth";

const PrivateRoute = ({ component: Component, documentTitle, path, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth();
    const location = useLocation();

    // Set a document title if one has been passed, otherwise default to the initial value
    useEffect(() => {
        if (documentTitle) {
            document.title = documentTitle;
        }
    }, [ documentTitle ]);

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }

        const loginAndRedirect = async () => {
            await loginWithRedirect({
                appState: { targetUrl: location }
            });
        };

        loginAndRedirect();
    }, [ loading, isAuthenticated, loginWithRedirect, location ]);

    const render = props => isAuthenticated === true
        ? <Component {...props} />
        : null;

    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
