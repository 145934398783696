import { useMemo } from "react";
import { useSelector } from "react-redux";

/*
Receives an array or roles to check against the users roles array and
returns a hash with the roles checked as keys and the booleans as values
*/

export const useHasRoles = (...searchedRoles) => {
	const groups = useSelector((state) => state.shell?.content?.groups);

	return useMemo(() => {
		return groups.some(group => searchedRoles.includes(group.groupGroupName));
	}, [ groups, searchedRoles ]);
};

export default useHasRoles;
