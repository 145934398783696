import React from "react";

export default {
	common: {
		assignSelected: {
			assignRoles: "Assign Roles",
			assignSelected: "Assign selected",
			clear: "Clear",
			selectPerson: "Select Person",
			selectRole: "Select Role",
			selectRolePlaceholder: "Select a Role...",
			selectPersonPlaceholder: "Select a Person..."
		},
		confirmationModal: {
			cancelButtonLabel: 'Cancel',
			confirmButtonLabel: 'Confirm'
		},
		commentDisplayModal: {
			cancelCommentLabel: "Cancel",
			columnHeaders: {
				comment: "Comment"
			}
		},
		dropdown: {
			noResultsMessage: "No results found"
		},
		setView: {
			cancel: "Cancel",
			instructions: "Choose which columns to view on the Proposal List. The list can show a maximum of eleven columns " // todo: may need to generalize a bit
				+ "at a time. Settings will persist until you log out of Cayuse.",
			noOptions: "Unable to find columns for this list",
			triggerButtonLabel: "Set View",
			update: "Update"
		},
		table: {
			noResults: "No Results",
			selectAll: "Select all",
			selectRow: "Select row"
		},
		notifications: {
			defaultErrorMsg: "Something ain't right",
			login: "Login",
			loginMessage: "You need to login"
		}
	},
	pagingControls: {
		ariaNextItem: "Next Item",
		ariaPreviousItem: "Previous Item",
		paginationDropdownAriaLabel: "Number of Items",
		showing: "Showing",
		xOfYSeparator: "of",
		items: "items"
	},

	searchBar: {
		searchPlaceholder: "Start typing to search"
	},
	tasks: {
		createdBy: "Created By: ",
		heading: "My Tasks",
		newTask: "New Task",
		searchByLabel: "Search by Task ID, Task, Created by, or Assigned to",
		assignTo: "Assign To",
		assignToPlaceholder: "Select a person...",
		confirmCancelTitle: "Unsaved Task",
		confirmCancel: "There are unsaved changes. Are you sure you want to lose them?",
		due: "Due Date",
		filterButtonGroupAriaLabel: "Task List Filters",
		task: "Task",
		request: "Request",
		requestStatus: "Status",
		url: "URL",
		open: "Open",
		closed: "Closed",
		urlPlaceholder: "Add url...",
		inactiveUser: "Selected user is inactive.",
		invalidUrl: "Please include http or https in the URL.",
		project: "Project",
		newEndDate: "New End Date",
		reason: "Reason",
		reasonText: "This response will be included on any official time extention paperwork filedwith the sponsor",
		additionalInfo: "Additional Info (internal only)",
		additionalInfoText: "Anything else your Admin team should know about the extension",
		dueDateOver: "can't reassign task with past Due date",
		noResultsSelectFilter: "No results to display.",
		filters: {
			assignedToMe: "Assigned to Me",
			createdByMe: "Created by Me",
		},
		tabLabels: {
			form: "Notes",
			request: "Request"
		},
		actionButtonLabels: {
			assignAndSend: "Assign and Send",
			saveChanges: "Save Changes",
			close: "Close Task",
			cancel: "Cancel",
			busy: "Creating task"
		},
		notifications: {
			taskSent: "Your new task has been assigned and sent.",
			taskUpdated: "Your changes have been saved.",
		},
		taskTypes: {
			CAYUSE_AD_HOC: "Ad Hoc Task",
			CAYUSE_SP_AD_HOC: "SP Ad Hoc Task",
			CAYUSE_SP_SYSTEM: "SP System Task",
			CAYUSE_COI_DISCLOSURE: "COI Disclosure",
			CAYUSE_MY_REQUEST: "Request"
		},
		widget: {
			Make_Request: "Make a Request",
			WigetHeader: "Widget Header",
			Create_Request: "Create Request",
			Click_To_Start: "Click to start",
			makeARequestActionButtonLables: {
				cancel: "Cancel",
				submit: "Submit",
			},
			APIMessage: {
				success: "Your request was submitted."
			}
		},
		taskUpdatePayload: {
			category: "CAYUSE_MY_REQUEST",
			status: "Open",
			unLinkUser: "Unlinked User"
		},
		defaultForm: {
			REQUEST_KEY: "I would like to..."
		},
		unsavedChangesAlertDialog: {
			title: "Leave Page?",
			description: "Changes you made will not be saved.",
			confirmText: "Leave Page",
		},
	},
	createTask: {
		breadcrumbs: {
			home: "Home",
			newTask: "New Task",
			editTask: "Task",
		},
	},
};
