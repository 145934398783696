export const getToDisplayAndFromDisplayData = (row, userAccountId) => {
	if ((userAccountId && userAccountId === row.assignee) || (row.category === "CAYUSE_SP_SYSTEM" && row.assignee == null)) {
		row.toDisplay = "Me";
	} else {
		if (row.variables && row.variables?.assignee) {
			row.toDisplay = row.variables?.assignee;
		}
	}

	if (userAccountId && userAccountId === row.owner) {
		row.fromDisplay = "Me";
	} else {
		if (row.variables && row.variables?.assignedFrom) {
			row.fromDisplay = row.variables?.assignedFrom;
		}
	}

	return row;
};