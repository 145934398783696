import React from "react";
import PropTypes from "prop-types";
import Icon from "@cayuse/shared-react.components.icon";
import TextInput from "@cayuse/shared-react.components.form.inputs.text";

import styles from "./styles.css";

export const SimpleSearchBar = ({
	onChange,
	value,
	onClear,
	placeholder,
	label,
	isLoading,
	resultsCount,
}) => {
	const getLoadingMessage = () => {
		if (isLoading) {
			return "Searching...";
		} else if (!isLoading && resultsCount > 0) {
			return `${resultsCount} results returned`;
		} else if (!isLoading && resultsCount === 0) {
			return "No results returned";
		}
	};

	return (
		<>
			<span role="alert" className="accessibility-text" aria-live="polite" aria-atomic="true">
				{getLoadingMessage()}
			</span>
			<label data-component="SimpleSearchBar" className={styles.simpleSearchBar} data-testid="simple-search-bar">
				<span>{label}</span>
				<Icon name="search" className={styles.searchIcon} />
				<TextInput
					className={styles.input}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					data-testid="simple-search-bar-input"
				/>
				{value?.length > 0 && (
					<button
						type="button"
						aria-label="clear search value"
						data-testid="clear-search"
						onClick={onClear}
						className={styles.clearButton}
					>
						<Icon
							name="close"
							className={styles.clearIcon}
							aria-label="clear search value"
						/>
					</button>
				)}
			</label>
		</>
	);
};

SimpleSearchBar.propTypes = {
	onClick: PropTypes.func,
	onClear: PropTypes.func,
	isLoading: PropTypes.bool,
	resultsCount: PropTypes.number,
};
