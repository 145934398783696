import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useParams } from "react-router-dom";

import { TaskForm } from "@cayuse/shared-react.components.task-form";
import SectionHeader from "@cayuse/shared-react.components.section-header";
import BasePadding from "@bit/cayuse.react.components.base-padding";
import { notify } from "@bit/cayuse.react.components.banner-notification";
import { AlertDialog } from "@cayuse/shared-react.components.modal-dialog";

import useDictionary from "hooks/useDictionary";
import useHasRoles from "hooks/useHasRoles";

import styles from "./styles.css";

export const Edit = props => {
	const history = useHistory();
	const params = useParams();
	const tasksDictionary = useDictionary("tasks");
	const createTaskDictionary = useDictionary("createTask");

  const isAdminAdministrator = useHasRoles(
    "RS Admin Administrator",
    "Task Administrator",
  );
  const isSpAdministrator = useHasRoles(
    "SP System Administrator"
  );

  const [ admin, setAdmin ] = useState(false);
  const [ isShowConfirmCancelModal, setShowConfirmCancelModal ] = useState(false);

  const [ taskCounterId, setTaskCounterId ] = useState(params.taskCounterId ?? "Task");

	const redirectToHome = () => history.push("/");

	const preparePayload = payload => {
		payload.applicationHostUrl = `${window.location.protocol}//${window.location.hostname}/`;

		return payload;
	};

	const onSuccess = message => {
		notify(message, { type: "success" });
		redirectToHome();
	};

	const onCancel = ({ unsavedChanges }) => {
		if (unsavedChanges) {
			setShowConfirmCancelModal(true);
		} else {
			redirectToHome();
		}
	};

	const onAbortCancel = () => {
	  setShowConfirmCancelModal(false);
	};

	const allowToRemoveAttachmentsByRoles = [
		isAdminAdministrator ? "RS Admin Administrator" : null,
		isAdminAdministrator ? "Task Administrator" : null,
	].filter(Boolean);

	return (
		<div className={styles.taskPage}>
			<SectionHeader
				header={createTaskDictionary.breadcrumbs.editTask ?? "Task"}
				breadcrumbs={[
					{
						text: createTaskDictionary.breadcrumbs.home ?? "Home",
						link: "/"
					},
					{
						text: taskCounterId,
					},
				]}
			/>
			<BasePadding>
				<div className={styles.taskFormWrapper}>
					<TaskForm
						fieldProps={{
							permissions: {
								isAdmin: admin,
							},
						}}
						onBeforeSuccess={() => {
							return new Promise(resolve => setTimeout(resolve, 1400));
						}}
						onTaskLoaded={task => {
							setAdmin(
								isSpAdministrator && task?.category?.startsWith("CAYUSE_SP_")
								|| isAdminAdministrator && task?.category?.startsWith("CAYUSE_AD_"),
							);
							setTaskCounterId(task?.taskCounterId ?? "Task");
						}}
						allowToRemoveAttachmentsByRoles={allowToRemoveAttachmentsByRoles}
						taskId={params.id}
						onCancel={onCancel}
						onSuccess={onSuccess}
						preparePayload={preparePayload}
						renderActions={component => {
							const container = document.querySelector("div.layout");

							return container ? createPortal(component, container) : null;
						}}
					/>
				</div>
			</BasePadding>
			<AlertDialog
					title={tasksDictionary.unsavedChangesAlertDialog.title ?? "Leave Page?"}
				description={tasksDictionary.unsavedChangesAlertDialog.description ?? "Changes you made will not be saved."}
				isOpen={isShowConfirmCancelModal}
				onConfirm={redirectToHome}
				onCancel={onAbortCancel}
				confirmText={tasksDictionary.unsavedChangesAlertDialog.confirmText ?? "Leave page"}
			/>
		</div>
	);
};
