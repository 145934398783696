import { tasks as p } from "store/constants";
import {
    reduceIsFetching,
    reduceSetError,
    reduceShallowContent,
    initialState as initialFetchableState
} from "store/factories/fetchable";
import {
    reduceSetItems,
    reduceSetSort,
    reduceSetPaging,
    addItems,
    initialState as initialFeedState,
    reduceSetSearch,
    reduceSetSummary
} from "store/factories/feed";



const initialState = {
    ...initialFetchableState,
    content: {
        filters: {
            assignedToMe: true,
            createdByMe: false,
            openStatus: true,
            closedStatus: false,
            inProgressStatus: true,
            onHoldStatus: true,
        },
        search: {
            searchText: "",
        },
        taskVariable: {},
        ...initialFeedState.content
    }
};

export const reduceSetFilters = (state, { filters }) => ({
    ...state,
    content: {
        ...state.content,
        filters: {
            ...state.content.filters,
            ...filters
        }
    }
});

export function tasks(state = initialState, action) {
    switch (action.type) {
        case p.IS_FETCHING:
            return reduceIsFetching(state, action);
        case p.SET_ERROR:
            return reduceSetError(state, action);
        case p.SET_CONTENT:
            return reduceShallowContent(state, action);
        case p.SET_ITEMS:
            return reduceSetItems(state, action);
        case p.SET_SORT:
            return reduceSetSort(state, action);
        case p.SET_PAGING:
            return reduceSetPaging(state, action);
        case p.ADD_ITEMS:
            return addItems(state, action);
        case p.RESET:
            return initialState;
        case p.SET_SUMMARY:
            return reduceSetSummary(state, action);
        case p.SET_SEARCH:
            return reduceSetSearch(state, action);
        case p.SET_FILTERS:
            return reduceSetFilters(state, action);
        default:
            return state;
    }
}


