import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from "@cayuse/shared-react.components.icon-button";

import { findTasks as loadTasks, setFilters, setPaging, setSearch, setSort } from "store/actions/tasks";
import { debounce } from "utils";
import { useDictionary, usePagination } from "hooks";
import BasePadding from "components/BasePadding";
import VerticalSpacing from "components/VerticalSpacing";
import SubHeader from "components/SubHeader";
import FeedTable from "components/FeedTable";
import { SimpleSearchBar } from "components/SimpleSearchBar";

import styles from "./styles.css";
import { Divider } from "semantic-ui-react";
import { Checkbox } from "@cayuse/shared-react.components.form.checkbox";


const TASK_LIST_ID = "view-list-task";

const activeColumns = [
    { keys: [ "taskCounter" ], columnHeader: "Task ID", slug: "taskCounter", sortKey: "taskCounterNumeric" },
    { keys: [ "task" ], columnHeader: "Task", slug: "task", sortKey: "nameLowercase" },
    { keys: [ "task-type" ], columnHeader: "Type", slug: "task-type", sortKey: "categoryKeyword" },
    { keys: [ "fromDisplay" ], columnHeader: "Created by", slug: "from", sortKey: "assignedFrom" },
    { keys: [ "toDisplay" ], columnHeader: "Assigned to", slug: "to", sortKey: "assignee" },
    { keys: [ "createdOn" ], columnHeader: "Created", slug: "createdOn", sortKey: "createTime" },
    { keys: [ "lastUpdated" ], columnHeader: "Last Activity", slug: "lastUpdated", sortKey: "lastUpdated" },
    { keys: [ "dueOn" ], columnHeader: "Due", slug: "dueOn", sortKey: "dueDate" },
    { keys: [ "status" ], columnHeader: "Status", slug: "status" },
];

const TaskListView = ({ onRowClick }) => {
    const history = useHistory();
    const dictionary = useDictionary("tasks");
    const { pageLimit, pageOffset, setPageLimit, setPageNumber } = usePagination("tasks", setPaging);

    const {
        content: {
            items: rows,
            totalItems,
            sort,
            filters,
            search: { searchText = "" },
        },
        isFetching: isLoading,
        isRefresh,
    } = useSelector(state => state.tasks);

    useEffect(() => {
        clearExpiredSortTable();
        loadTasks();
    }, []);

    const onNewTaskClick = () => {
        history.push("/tasks/create");
    };

    const changeFilter = params => {
        if ("pageLimit" in params) setPageLimit(params.pageLimit);
        if ("pageNumber" in params) setPageNumber(params.pageNumber);
        if ("searchText" in params) setSearch(params.searchText);
        if ("sort" in params) setSort(params.sort);
        if ("assignedToMe" in params) setFilters({ assignedToMe: params.assignedToMe });
        if ("createdByMe" in params) setFilters({ createdByMe: params.createdByMe });
        if ("openStatus" in params) setFilters({ openStatus: params.openStatus });
        if ("closedStatus" in params) setFilters({ closedStatus: params.closedStatus });
        if ("inProgressStatus" in params) setFilters({ inProgressStatus: params.inProgressStatus });
        if ("onHoldStatus" in params) setFilters({ onHoldStatus: params.onHoldStatus });

        loadTasks();
    };

    const handleSortChange = (sortKey, sort) => {
        const sortFilter = {
            sortKey: sortKey,
            sortDirection: sort?.direction === "descending" || sortKey!== sort?.column ? "ascending" : "descending",
            expiryTime: new Date().getTime() + 24 * 60 * 60 * 1000
        };
        localStorage.setItem("myTasksTableFilter", JSON.stringify([ sortFilter ]));
        changeFilter({ sort: sortKey });
    };

    const findTasksWithDebounce = useMemo(() => debounce(loadTasks, 400), []);

    const onSearchTextChange = e => {
        setPageNumber(1);
        setSearch({ searchText: e.target.value });
        findTasksWithDebounce();
    };

    const onFilterChange = event => {
        changeFilter({ pageNumber: 1, [event.target.value]: event.target.checked });
    };

    const clearExpiredSortTable = () => {
        const myTasksTableFilter = JSON.parse(localStorage.getItem("myTasksTableFilter"));
        if (myTasksTableFilter) {
            if (new Date().getTime() > myTasksTableFilter[0].expiryTime) {
                localStorage.removeItem("myTasksTableFilter");
                changeFilter({ sort: "dueDate" });
            }
        }
    };

    return (
        <VerticalSpacing>
            <BasePadding>
                <div id={TASK_LIST_ID} className={styles.taskListContainer}>
                    <div className={styles.taskListTop}>
                        <div className={`ui top attached primary segment ${styles.taskListHeaderContainer}`}>
                            <div className={styles.taskListHeaderSpacer} />
                            <SubHeader headerLevel="2" className={styles.taskListHeader}>
                                My Tasks
                            </SubHeader>
                            <div className={styles.taskListNewIcon}>
                                <IconButton
                                    icon="plus"
                                    labelPosition="left"
                                    primary
                                    onClick={onNewTaskClick}
                                    data-testid="add-new-task-button"
                                >
                                    {dictionary.newTask || "New Task"}
                                </IconButton>
                            </div>
                        </div>
                        <div className={styles.searchBar}>
                            <SimpleSearchBar
                                label={dictionary.searchByLabel}
                                placeholder={dictionary.searchByLabel}
                                value={searchText}
                                onChange={onSearchTextChange}
                                onClear={() => changeFilter({ searchText: "" })}
                                isLoading={isLoading}
                                resultsCount={totalItems}
                            />
                        </div>
                    </div>
                    <div className={styles.tableWrapper}>
                        <div className={styles.filterContainer}>
                            <div role="group" aria-labelledby="group_quick-filters" className={styles.filtersGroup}>
                                <label id="group_quick-filters">Quick Filters</label>
                                <Checkbox
                                    label={dictionary.filters.assignedToMe}
                                    value="assignedToMe"
                                    checked={filters.assignedToMe}
                                    onChange={onFilterChange}
                                />
                                <Checkbox
                                    label={dictionary.filters.createdByMe}
                                    value="createdByMe"
                                    checked={filters.createdByMe}
                                    onChange={onFilterChange}
                                />
                            </div>
                            <Divider style={{ background: "var(--color-dark-grey)" }} fitted />
                            <div role="group" aria-labelledby="group_status" className={styles.filtersGroup}>
                                <label id="group_status">Status</label>
                                <Checkbox
                                    label="Open"
                                    value="openStatus"
                                    checked={filters.openStatus}
                                    onChange={onFilterChange}
                                />
                                <Checkbox
                                    label="In Progress"
                                    value="inProgressStatus"
                                    checked={filters.inProgressStatus}
                                    onChange={onFilterChange}
                                />
                                <Checkbox
                                    label="On Hold"
                                    value="onHoldStatus"
                                    checked={filters.onHoldStatus}
                                    onChange={onFilterChange}
                                />
                                <Checkbox
                                    label="Closed"
                                    value="closedStatus"
                                    checked={filters.closedStatus}
                                    onChange={onFilterChange}
                                />
                            </div>
                        </div>
                        <FeedTable isLoading={isLoading}
                                   isRefresh={isRefresh}
                                   headers={activeColumns}
                                   rows={rows}
                                   totalItems={totalItems}
                                   pageLimit={pageLimit}
                                   pageOffset={pageOffset}
                                   onPageChange={pageNumber => changeFilter({ pageNumber })}
                                   onPageLimitChange={pageLimit => changeFilter({ pageLimit })}
                                   onSortChange={handleSortChange}
                                   noResultsLabel={dictionary.noResultsSelectFilter || "No Saved Tasks"}
                                   isSelectable={true}
                                   onRowClick={onRowClick}
                                   sort={sort}
                                   containerClassname={styles.tableContainer}
                        />
                    </div>
                </div>
            </BasePadding>
        </VerticalSpacing>

    );
};

export default TaskListView;
