import { save, request, put } from "utils/proxy";
import store from "utils/store";
import { tasks as constants } from "store/constants";
import {
    isNonEmptyArray,
    isObject,
    isNonEmptyString,
    parseJSON,
    findUnitById,
} from "utils";
import { actionFactory as feedActionFactory } from "store/factories/feed";
import { actionFactory as fetchableActionFactory } from "store/factories/fetchable";
import { FLOWABLE_BASE_URL, ADMINISTRATION_PERSON_USER_URL, TASK_FILTERS } from "globals/constants";
import Pager from "components/Pager";
import moment from "moment";
import { getToDisplayAndFromDisplayData } from "utils/tasks";
require("moment/min/locales.min");


const { setIsFetching, setError, setContent } = fetchableActionFactory(constants);

const { setPaging, setSort } = feedActionFactory(constants);

const dispatch = store.dispatch;

const setFilters = filters => dispatch({ type: constants.SET_FILTERS, filters });

let userAdditionalDetailsCache = JSON.parse(localStorage.getItem("userAdditionalDetailsCache")) || {};

const getUserAdditionalDetailsFromCache = userId => {
    if (userId) {
        return userAdditionalDetailsCache[userId];
    }

    return null;
};

const getUserAdditionalDetails = async userId => {
    if (!userId) {
        return null;
    }
    if (getUserAdditionalDetailsFromCache(userId)) {
        const additionalDataFromCache = getUserAdditionalDetailsFromCache(userId);

        return additionalDataFromCache || "";
    }
    const url = `${ADMINISTRATION_PERSON_USER_URL}/${userId}`;
    try {
        const response = await request(url);
        if (isObject(response)) {
            const unitId = response?.affiliations?.[0]?.unitId;
            let unit = "";
            if (unitId) {
                try {
                    const data = await findUnitById(unitId);
                    unit = data?.name;
                } catch (error) {
                    console.error("Error fetching unit:", error);
                }
            }
            const contactEmail = response?.contactEmail || "";
            const additionalData = [ contactEmail, unit, new Date() ];
            userAdditionalDetailsCache[userId] = additionalData;
            localStorage.setItem("userAdditionalDetailsCache", JSON.stringify(userAdditionalDetailsCache));

            return additionalData || "";
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
};

const cleanUpExpiredAdditionalUserInfo = () => {
    const storage = JSON.parse(localStorage.getItem("userAdditionalDetailsCache"));
    if (storage) {
        let freshOnly = {};
        for (let k in storage) {
            if (storage[k][2] && Math.floor(new Date() - new Date(storage[k][2])) / 86400000 > 1) {
                continue;
            }
            freshOnly[k] = storage[k];
        }
        localStorage.setItem("userAdditionalDetailsCache", JSON.stringify(freshOnly));
        userAdditionalDetailsCache = JSON.parse(localStorage.getItem("userAdditionalDetailsCache"));
    }
};

const updateUserAdditionalDetailsCache = async tasks => {
    cleanUpExpiredAdditionalUserInfo();
    let owners =  [ ...new Set(tasks
        .filter(t => !t.ownerExtra && t.owner && !getUserAdditionalDetailsFromCache(t.owner))
        .map(d => d.owner)) ];
    let updatedTasksOwner = await Promise.all(owners
        .map(async owner =>  await getUserAdditionalDetails(owner)));

    let assignees = [ ...new Set(tasks
        .filter(t => !t.assigneeExtra && t.assignee && !getUserAdditionalDetailsFromCache(t.assignee))
        .map(d => d.assignee)) ];
    let updatedTasksAssignee = await Promise.all(assignees
        .map(async assignee => await getUserAdditionalDetails(assignee)));

    return [ ...updatedTasksOwner, ...updatedTasksAssignee ];
};

const findTasks = ({ isRefresh = false } = { isRefresh: false }) => {
    const userAccountId = store.getState().shell?.content?.user?.id;

    const _assignedToMe = store.getState().tasks.content.filters.assignedToMe;
    const _createdByMe = store.getState().tasks.content.filters.createdByMe;
    const _openStatus = store.getState().tasks.content.filters.openStatus;
    const _closedStatus = store.getState().tasks.content.filters.closedStatus;
    const _inProgressStatus = store.getState().tasks.content.filters.inProgressStatus;
    const _onHoldStatus = store.getState().tasks.content.filters.onHoldStatus;


    const searchText = store.getState().tasks.content.search.searchText;
    const { pageNumber, pageLimit } = store.getState().tasks.content.paging;
    const pageOffset = Pager.pageOffsetByPageNumber(pageNumber);

    const timezone = store.getState().shell?.content?.tenant?.timezone || "UTC";
    const sortColumn = store.getState().tasks?.content?.sort?.column;
    const sortDirection = store.getState().tasks?.content?.sort?.direction;
    const dictionary = store.getState().dictionary?.tasks?.taskTypes || {};

    const lookupTypeName = (taskType) => {
        return dictionary[taskType] || "Other";
    };

    const params = {
        includeTaskLocalVariables: true,
        includeProcessVariables: true,
        sort: sortColumn,
        order: sortDirection.slice(0, -6), // strip off "ending" to "asc" or "desc" for Flowable
        start: pageOffset * pageLimit,
        size: pageLimit,
        taskVariables: [
            {
                name: "category",
                value: "CAYUSE_%",
                operation: "like",
                type: "string"
            }
        ]
    };

    if (searchText?.length > 0) {
        params.searchText = searchText;
    }

    if (_assignedToMe && _createdByMe) {
        params.filterByUser = TASK_FILTERS.ALL_MINE;
    } else if (_assignedToMe) {
        params.filterByUser = TASK_FILTERS.ASSIGNED_TO_ME;
    } else if (_createdByMe) {
        params.filterByUser = TASK_FILTERS.CREATED_BY_ME;
    }

    const statuses = [];
    if (_openStatus) {
        statuses.push(TASK_FILTERS.OPEN);
    }
    if (_closedStatus) {
        statuses.push(TASK_FILTERS.CLOSED);
    }
    if (_onHoldStatus) {
        statuses.push(TASK_FILTERS.ON_HOLD);
    }
    if (_inProgressStatus) {
        statuses.push(TASK_FILTERS.IN_PROGRESS);
    }
    if (statuses.length > 0) {
        params.filterByStatus = statuses;
    }

    const paramsArray = Object.entries(params)
    .filter(([_, value]) => value !== undefined) // Filter out parameters with undefined values
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`); // Convert each parameter key-value pair to a URL-encoded string

    const url = `${FLOWABLE_BASE_URL}/cayuse-api/query/tasks/admin-platform-my-tasks-v2/nested?${paramsArray.join("&")}`;
    // Construct the URL by joining the paramsArray with "&" as the delimiter, and append the URL parameters from urlParams using the toString() method

    setIsFetching(true, { isRefresh });
    setError("");

    return request(url, params)
        .then(data => {
            const totalItems = data?.total;
            const taskFeedItems = data?.data;
            const hasTaskFeedItems = isNonEmptyArray(taskFeedItems);

            function getMappedColumnValues() {
                return taskFeedItems.map(taskFeedItem => {
                    getToDisplayAndFromDisplayData(taskFeedItem, userAccountId);

                    //map api values to the column structure
                    return {
                        id: taskFeedItem.id,
                        assignee: taskFeedItem.assignee,
                        assigneeExtra: getUserAdditionalDetailsFromCache(taskFeedItem.assignee),
                        task: taskFeedItem.name,
                        category: taskFeedItem.category,
                        owner: taskFeedItem.owner,
                        ownerExtra: getUserAdditionalDetailsFromCache(taskFeedItem.owner),
                        "task-type": lookupTypeName(taskFeedItem.category),
                        createdOn: moment(taskFeedItem.createTime).tz(timezone)
                            .format("L"),
                        dueOn: taskFeedItem.dueDate == null
                            ? ""
                            : moment(taskFeedItem.dueDate).tz("UTC").format("L"),
                        dueOnTimestamp: taskFeedItem.dueDate,
                        from: taskFeedItem.variables?.assignedFrom,
                        to: taskFeedItem.variables?.assignee,
                        status: taskFeedItem.endTime === undefined || taskFeedItem.endTime === null ? taskFeedItem.variables?.status : "Closed",
                        url: taskFeedItem.variables?.url,
                        description: taskFeedItem.description,
                        completedBy: taskFeedItem.variables?.completedBy,
                        taskCounter: taskFeedItem.variables?.taskCounter,
                        lastUpdated: moment(taskFeedItem.variables?.lastUpdated).tz(timezone).format("L"),
                        toDisplay:  taskFeedItem.toDisplay,
                        fromDisplay: taskFeedItem.fromDisplay
                    };
                });
            }

            const mappedColumnValues = getMappedColumnValues();
            const searchItems = hasTaskFeedItems ? formatSearchItems(mappedColumnValues) : [];

            updateUserAdditionalDetailsCache(mappedColumnValues)
                .then(d => {
                    if (d && d.length >= 1) {
                        setContent({ items: getMappedColumnValues() });
                    }
                });

            setContent({ items: mappedColumnValues, totalItems, searchItems });
        })
        .catch(error => {
            let msg = isNonEmptyString(error)
                ? error
                : error.message || "Something went wrong";

            setError(msg);
        })
        .finally(() => {
            setIsFetching(false, { isRefresh: false });
        });
};

const setSearch = search => dispatch({ type: constants.SET_SEARCH, search });

const formatSearchItems = items => { // todo: this should likely live elsewhere for re-use
    const searchItems = [];
    {
        const mapItems = items.map(pageItem => {
            return {
                key: pageItem.task,
                value: JSON.stringify({ key: "task", val: pageItem.task }),
                text: pageItem.task
            };
        });

        const map = new Map();
        for (const item of mapItems) {
            const itemPair = `${item.key}:${item.val}`;
            if (!map.has(itemPair)) {
                map.set(itemPair, true);
                searchItems.push(item);
            }
        }
    }

    return searchItems;
};


const setTaskSearchBar = search => {
    const searchValue = isNonEmptyString(search) ? parseJSON(search) : search;

    setSearch(searchValue);
};

const refreshTasks = () => {
    findTasks({ isRefresh: true });
};

const getTaskVariables = taskId => {
    setError("");

    const url = `${FLOWABLE_BASE_URL}/platform-api/tasks/${taskId}/variables`;

    setContent({ taskVariable: {} });

    request(url).then(response => {
        setContent({ taskVariable: response });
    }).catch(error => {
        let msg = isNonEmptyString(error)
                ? error
                : error.message || "Something went wrong";

            setError(msg);
    }).finally(() => {
        setIsFetching(false, { isRefresh: false });
    });
};

const createTask = taskPayload => {
    return save(`${FLOWABLE_BASE_URL}/cayuse-tasks`, taskPayload);
};

const editTask = (initialTaskId, taskPayload, requestTabData) => {
    return put(`${FLOWABLE_BASE_URL}/cayuse-tasks/${initialTaskId}`, taskPayload)
    .then(() => {
        if(requestTabData) {
            return save(`${FLOWABLE_BASE_URL}/platform-api/tasks/${initialTaskId}/save-form`, requestTabData);
        }
    });
};

export {
    setIsFetching,
    setError,
    setContent,
    findTasks,
    setTaskSearchBar,
    createTask,
    editTask,
    refreshTasks,
    getTaskVariables,
    setFilters,
    setSearch,
    setPaging,
    setSort
};
