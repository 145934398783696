export const IS_DEV = process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test" || process.env.REACT_APP_ENVIRONMENT === "develop";

/* Product Info */
export const PRODUCT_KEY = "HOME";
export const PENDO_PRODUCT_NAME = "My Tasks";

/* Paging */
export const DEFAULT_PAGE_LIMIT = 10;

/* Routes */
export const FORMS_BASE_ROUTE = "/forms";
export const PROJECTS_BASE_ROUTE = "/projects";
export const PROPOSALS_BASE_ROUTE = "/proposals";
export const RESOLUTIONS_BASE_ROUTE = "/resolutions";
export const LOGOUT_ROUTE = "/logout";
export const LOGOUT_REDIRECT_ROUTE = `https://${process.env.REACT_APP_AUTH_DOMAIN}/logout`;

/* API Endpoints */
export const API_ORGANIZATION = "/api/organization";
export const API_FORMS = "/api/forms";
export const API_GROUP_ROLES = "/api/grouproles";
export const API_PROJECT = "/api/project";
export const API_PROPOSAL = "/api/proposal";
export const API_PROPOSAL_FEED = "/api/proposal-feed";
export const API_PROPOSAL_STATUS = "/api/status/proposal";
export const API_TENANT = "/api/tenant";

/* Flowable Endpoints */
export const FLOWABLE_DESIGN_URL = process.env.REACT_APP_FLOWABLE_DESIGN_URL;
export const FLOWABLE_BASE_URL = process.env.REACT_APP_FLOWABLE_ENGAGE_URL;
export const FLOWABLE_CAYUSE_ENDPOINT = "/api/v2";

/*Flowable Constants*/
export const FLOWABLE_ACCESS_REQUEST_FOR = "accessRequestedFor";
export const FLOWABLE_SUBAWARDEE = "subawardee";
export const FLOWABLE_PRINCIPAL_INVESTIGATOR_FOR_MOD = "principalInvestigatorforMod";

/*My requests configuration*/
export const SHOW_MY_REQUESTS = `${process.env.REACT_APP_CAYUSE_SHOW_MY_REQUESTS}`;

/* Administration Service Endpoints */
export const ADMINISTRATION_PERSON_URL = "/api/v2/person";
export const ADMINISTRATION_PERSON_USER_URL = "/api/v2/person/user";
export const ADMINISTRATION_USER_URL = "/api/v2/user/user";
export const ADMINISTRATION_UNIT_URL = "/api/v2/unit";
export const ADMINISTRATION_ORGANIZATION_URL = "/api/v2/organization";
export const ADMINISTRATION_AD_HOC_TEAM_URL = "/api/v2/user/adhocteam";

/* Group / Role Names */
export const ROLE_SP_PROPOSAL_ADMINISTRATOR = "SP Proposal Administrator";
export const ROLE_SP_PROPOSAL_REVIEWER = "SP Proposal Reviewer";

/* Local & Session Storage Keys */
export const STORAGE_SHELL_CONTENT = "shell:content";

/* Limits */
export const MAX_ACTIVE_COLUMN_HEADERS = 11;

/* Misc. Keys */
export const SELECT_ALL_KEY = "__all__";
export const SELECT_ANY_KEY = "*";
export const NEW_RULE_ID = "__add__";

export const RULE_TYPES = {
	AD_HOC_NOTIFICATION: "AD_HOC_NOTIFICATION",
	AD_HOC_REVIEW: "AD_HOC_REVIEW",
	UNIT_REVIEW: "UNIT_REVIEW"
};

export const DEFAULT_COLUMN_KEYS = {
	ADMIN_UNIT: "cayuse-unit-finder1",
	INSTRUMENT_TYPE: "text8",
	PI: "cayuse-research-team2",
	PRIME_SPONSOR: "text7",
	PROJECT_END_DATE: "date1",
	PROJECT_START_DATE: "date3",
	PROJECT_TITLE: "projectTitle",
	PROPOSAL_NUMBER: "proposalNumber",
	PROPOSAL_TYPE: "single-select1",
	PROPOSED_TOTAL_AMOUNT: "float-number1",
	SPONSOR: "text9",
	SPONSOR_DEADLINE: "date2",
	STATUS: "status",
};

/* i18n */
export const SUPPORTED_LOCALES = [
	"en-US",
	"en-CA",
	"en-SG"
];

export const DEFAULT_LOCALE = "en-SG";

/* Widths */
export const MAX_WIDTH = 16;
export const MID_WIDTH = 12;
export const MIN_WIDTH = 7;

export const TASK_FILTERS = {
	ALL_MINE: "ALL_MINE",
	ASSIGNED_TO_ME: "ASSIGNED_TO_ME",
	CREATED_BY_ME: "CREATED_BY_ME",
	OPEN: "OPEN",
	CLOSED: "CLOSED",
	ON_HOLD: "ON_HOLD",
	IN_PROGRESS: "IN_PROGRESS",
};
